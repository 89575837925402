<template>
    <AppLayout>
        <v-snackbar v-model="snackbarLockedUserRemoved" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Locked user removed</span>
            <v-btn text class="blue white--text" @click="snackbarLockedUserRemoved = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-snackbar v-model="snackbarLockedUserRemovedError" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Error removing locked user</span>
            <v-btn text class="red white--text" @click="snackbarLockedUserRemovedError = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>

        <v-row justify="center" class="pt-5" v-show="!isLoginReady && isLoginWithPushNotification && users.length > 1">
            <h1 class="text-h6 font-weight-light">Select an account to login</h1>
        </v-row>
        <v-row justify="center" class="py-5" v-show="!isLoginReady && isLoginWithPushNotification && users.length > 1">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0">
                <v-row justify="center">
                    <v-row justify="center" class="py-5">
                        <v-card elevation="12" min-width="300">
                            <v-divider class="ma-0"></v-divider>
                            <v-list class="ma-0 pa-0">
                                <v-list-item v-for="user in users" v-bind:key="user.id" v-on:click.prevent="sendPushNotification(user.id)" class="ma-0 py-1 px-4" style="border-bottom: 1px solid #C4C4C4">
                                    <v-list-item-avatar size="32" class="ma-0 pa-0">
                                        <font-awesome-icon icon="user-circle" style="font-size: 32px"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="ml-4 text-subtitle-1">
                                        {{user.name}}
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="red" href="#" @click.prevent="removeLockedUserById(user.id)">
                                            <font-awesome-icon icon="times" fixed-width style="font-size: 18px;"/>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>

        <v-row justify="center" class="py-5" v-show="isLoginReady">
            <h1 class="text-h6 font-weight-light">Login</h1>
        </v-row>
        <v-row justify="center" v-show="isLoginReady && isLoginWithPushNotification && users.length > 1">
            <a @click.prevent="switchUser" class="grey--text" style="text-decoration: underline;">Switch users</a>
        </v-row>
        <v-row justify="center" class="py-5" v-show="isLoginReady">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0">
                <v-expansion-panels accordion v-model="selectedPanelIndex">
                    <v-expansion-panel style="border-radius: 0px;" :readonly="isOnlyPanel" v-show="isMobilePanel" >
                        <v-expansion-panel-header class="text-overline" style="font-size: 16px!important;" :hide-actions="isOnlyPanel">Mobile</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row justify="center" v-show="isLoadingUserInfo" class="pt-5 pb-8">
                                <h2 class="text-h5 font-weight-light text-center">Please wait...</h2>
                            </v-row>
                            <div v-show="!isLoadingUserInfo && isLoginWithPushNotification && !isLoginTimeout">
                                <v-row justify="center" v-show="!isPushNotificationSent" class="pt-5 pb-8">
                                    <h2 class="text-h5 font-weight-light text-center">Sending notification...</h2>
                                </v-row>
                                <v-row justify="center" v-show="isPushNotificationSent">
                                    <v-col>
                                        <v-row justify="center" class="pb-6">
                                            <p class="text-h5 font-weight-light text-center">Look for a push notification</p>
                                        </v-row>
                                        <v-row justify="center">
                                            <v-img contain src="/img/push_notification_icon_300x300.png" max-height="200" max-width="200" @click="resendPushNotification"/>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" v-show="loginPushNotificationError" class="pt-8 pb-6">
                                    <p class="text-h5 red--text">Cannot send push notification</p>
                                </v-row>
                                <v-row justify="center" class="py-6" v-show="isPushNotificationSent">
                                    <v-btn outlined elevation="4" color="blue" @click.prevent="startQRCodeLogin">New Device</v-btn>
                                </v-row>
                            </div>
                            <div v-show="!isLoadingUserInfo && isLoginWithQrCode && !isLoginTimeout">
                                <!-- QR code -->
                                <v-row justify="center" v-show="isDisplayQRCode">
                                    <p class="text-h5 font-weight-light text-center">Snap this with LoginShield App</p>
                                </v-row>
                                <v-row v-show="isDisplayQRCode" justify="center">
                                    <div id="svgQrCodeContainer" @click="startQRCodeLogin"></div>
                                </v-row>
                                <v-row v-show="isDisplayQRCode && isNotificationEnabled" justify="center" class="pt-2 pb-6">
                                    <v-btn outlined elevation="4" color="blue" @click="showNotification">Notification</v-btn>
                                </v-row>
                                <v-row v-show="isLoadingQrCode" justify="center" class="pt-5 pb-8">
                                    <h2 class="text-h5 font-weight-light text-center">Generating link...</h2>
                                </v-row>
                                <v-row justify="center" v-show="loginQrCodeError" class="py-5">
                                        <p class="text-h5 red--text text-center">Cannot generate link</p>
                                </v-row>
                            </div>
                            <div v-show="isLoginTimeout">
                                <v-row justify="center" class="pt-10">
                                    <p class="red--text text-h5 text-center">Login is not complete</p>
                                </v-row>
                                <v-row justify="center" class="py-5">
                                    <v-btn elevation="6" class="blue white--text" v-on:click.prevent="startLoginActivity">
                                        Login
                                    </v-btn>
                                </v-row>
                            </div>
                            <div v-show="isLoginError">
                                <v-row justify="center" class="pt-10">
                                    <p class="red--text text-h5 text-center font-weight-light">An error occurred</p>
                                </v-row>
                                <v-row justify="center" class="py-5">
                                    <v-btn outlined elevation="4" color="blue" v-on:click.prevent="startLoginActivity">Try again</v-btn>
                                </v-row>
                            </div>
                            <v-row justify="center" class="pt-10" v-show="isLoginCancel">
                                <p class="text-h5 text-center text-center font-weight-light">Login request was cancelled</p>
                            </v-row>
                            <v-row justify="center" class="pt-10" v-show="isLoginSafetyNotice">
                                <p class="text-h5 text-center text-center font-weight-light">Check your email to continue</p>
                            </v-row>
                            <v-row justify="center" class="py-5" v-show="isLoginCancel || isLoginSafetyNotice">
                                <v-btn outlined elevation="4" color="blue" v-on:click.prevent="startLoginActivity">Try again</v-btn>
                            </v-row>
                            <div v-show="isLoginInitError">
                                <v-row justify="center" class="pt-10">
                                    <p class="red--text text-h5 text-center font-weight-light">The service is not currently available</p>
                                </v-row>
                                <v-row justify="center" class="py-5">
                                    <v-btn outlined elevation="4" color="blue" v-on:click.prevent="init">Try again</v-btn>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel style="border-radius: 0px;" :readonly="isOnlyPanel" v-show="isAndroidPanel">
                        <v-expansion-panel-header class="text-overline" style="font-size: 16px!important;" :hide-actions="isOnlyPanel">Android</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row justify="center" v-show="isLoadingUserInfo" class="pt-5 pb-8">
                                <h2 class="text-h5 font-weight-light text-center">Please wait...</h2>
                            </v-row>
                            <div v-show="!isLoadingUserInfo">
                                <v-row v-show="isLoadingQrCode" justify="center" class="pt-5 pb-8">
                                    <h2 class="text-h5 font-weight-light text-center">Generating link...</h2>
                                </v-row>
                                <v-row justify="center" class="pt-6" v-show="isLinkQueryReady">
                                    <!--  @click="openAndroidLink" -->
                                    <v-btn large outlined elevation="4" color="blue" :href="linkQueryAndroid" target="_blank" style="font-size: 16px;">Open App</v-btn>
                                </v-row>
                                <v-row justify="center" class="pt-10" v-show="isLoginCancel">
                                    <p class="text-h5 text-center text-center font-weight-light">Login request was cancelled</p>
                                </v-row>
                                <v-row justify="center" class="pt-10" v-show="isLoginSafetyNotice">
                                    <p class="text-h5 text-center text-center font-weight-light">Check your email to continue</p>
                                </v-row>
                                <v-row justify="center" class="py-5" v-show="isLoginCancel || isLoginSafetyNotice">
                                    <v-btn outlined elevation="4" color="blue" v-on:click.prevent="startLoginActivity">Try again</v-btn>
                                </v-row>
                                <!-- <v-row justify="center" class="pt-12">
                                    <a href="/software/" class="grey--text" style="text-decoration: underline;">Get the app</a>
                                </v-row> -->
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel style="border-radius: 0px;" :readonly="isOnlyPanel" v-show="isIosPanel">
                        <v-expansion-panel-header class="text-overline" style="font-size: 16px!important;" :hide-actions="isOnlyPanel">iOS</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!-- <v-row justify="center" class="py-6">
                               <p class="text-body-1 font-weight-light text-center">Coming Soon!</p>
                            </v-row> -->
                            <v-row justify="center" v-show="isLoadingUserInfo" class="pt-5 pb-8">
                                <h2 class="text-h5 font-weight-light text-center">Please wait...</h2>
                            </v-row>
                            <div v-show="!isLoadingUserInfo">
                                <v-row v-show="isLoadingQrCode" justify="center" class="pt-5 pb-8">
                                    <h2 class="text-h5 font-weight-light text-center">Generating link...</h2>
                                </v-row>
                                <v-row justify="center" class="pt-6" v-show="isLinkQueryReady">
                                    <!--  @click="openIOSLink"  -->
                                    <v-btn large outlined elevation="4" color="blue" :href="linkQueryIOS" target="_blank" style="font-size: 16px;">Open App</v-btn>
                                </v-row>
                                <v-row justify="center" class="pt-10" v-show="isLoginCancel">
                                    <p class="text-h5 text-center text-center font-weight-light">Login request was cancelled</p>
                                </v-row>
                                <v-row justify="center" class="pt-10" v-show="isLoginSafetyNotice">
                                    <p class="text-h5 text-center text-center font-weight-light">Check your email to continue</p>
                                </v-row>
                                <v-row justify="center" class="py-5" v-show="isLoginCancel || isLoginSafetyNotice">
                                    <v-btn outlined elevation="4" color="blue" v-on:click.prevent="startLoginActivity">Try again</v-btn>
                                </v-row>
                                <!-- <v-row justify="center" class="pt-12">
                                    <a href="/software/" class="grey--text" style="text-decoration: underline;">Get the app</a>
                                </v-row> -->
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row justify="center" class="pt-10">
                    <v-switch
                    v-show="['android', 'ios', 'unknown'].includes(platform)"
                    class="ml-5 mt-0"
                    style="display: inline-block;"
                    v-model="switchMoreOptions"
                    color="blue"
                    label="More options"
                    hide-details>
                    </v-switch>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <!-- <v-col> -->
                <!-- <v-row justify="center"> -->
                    <p class="pr-2"><a href="/software/">Get the app</a></p>
                    <p class="pl-2"><a href="/kb/recover-access/">Restore access</a></p>
                <!-- </v-row> -->
            <!-- </v-col> -->
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import { BrowserGateway } from '@cryptium/tigercomet-gateway-browser';
import AppLayout from '@/components/AppLayout.vue';

const ajax = require('axios');
// const { BrowserGateway } = require('@cryptium/tigercomet-gateway-browser');

function getNavigatorPlatform() {
    const ua = navigator.userAgent.toLowerCase();
    console.log('ua string: %s', ua);

    const isAndroid = ua.includes('android');
    const isIOS = ua.includes('iphone') || ua.includes('ipod') || ua.includes('ipad');
    const isWindows = ua.includes('win'); // windows, win32, win64, wow64
    const isMac = ua.includes('mac');
    const isLinux = ua.includes('linux');
    const isMobile = ua.includes('mobile');
    const isOnlyAndroid = isAndroid && !isIOS && !isWindows && !isMac;
    const isOnlyIOS = !isAndroid && isIOS && !isWindows && !isLinux;
    const isOnlyWindows = !isAndroid && !isIOS && isWindows && !isMac && !isLinux;
    const isOnlyMac = !isAndroid && !isIOS && !isWindows && isMac && !isLinux;
    const isOnlyLinux = !isAndroid && !isIOS && !isWindows && !isMac && isLinux && !isMobile;

    let platform;

    if (isOnlyAndroid) {
        platform = 'android';
    } else if (isOnlyIOS) {
        platform = 'ios';
    } else if (isOnlyWindows) {
        platform = 'windows';
    } else if (isOnlyMac) {
        platform = 'mac';
    } else if (isOnlyLinux) {
        platform = 'linux';
    } else if (isMobile) {
        platform = 'mobile';
    } else {
        platform = 'unknown';
    }

    return platform;
}

let gw;
let cacheSvgElement;

export default {
    components: {
        AppLayout,
    },

    data() {
        return {
            // state
            interactionId: null, // used when login is for specific context, we pass it to the server for special handling
            from: null, // used when we want to return user to a specific page after login, and there isn't any other interaction
            userId: null, // only if specified in query
            loginToken: null,
            isLoginActivity: false,
            isLoginWithPushNotification: false,
            isPushNotificationSent: false,
            isLoginWithQrCode: false,
            isLoginTimeout: false,
            isLoginError: false,
            isLoginCancel: false,
            isLoginSafetyNotice: false,
            isLoadingQrCode: false,
            isLoadingUserInfo: false,
            isQrCodeReady: false,
            loginQrCodeError: false,
            linkQuery: null,
            linkQueryAndroid: null,
            linkQueryIOS: null,
            linkQueryOther: null,
            isLinkQueryReady: false,
            isLoginInitError: false,
            platform: null,
            isMobilePanel: false,
            isAndroidPanel: false,
            isIosPanel: false,
            isOnlyPanel: false,
            selectedPanelIndex: null,
            pushNotificationUserId: null,
            switchMoreOptions: false,
            isLoginReady: false,
            // opt-in form
            name: localStorage.getItem('name') || '',
            email: localStorage.getItem('email') || '',
            phone: localStorage.getItem('phone') || '',
            isAgreeToTermsChecked: localStorage.getItem('isAgreeToTermsChecked') === 'true',
            checkedAgreeToTermsDate: localStorage.getItem('checkedAgreeToTermsDate') || '',
            isEmailValidationFailed: false,
            isPhoneValidationFailed: false,
            // login form
            username: localStorage.getItem('email') || '',
            loginPushNotificationError: false,
            // snackbars
            snackbarLockedUserRemoved: false,
            snackbarLockedUserRemovedError: false,
        };
    },

    watch: {
        isReady(value, oldValue) {
            console.log('login.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },

        /*
        name: function(name) {
            localStorage.setItem("name", name);
        },
        email: function(email) {
            localStorage.setItem("email", email);
            this.username = email;
            this.isEmailValidationFailed = email != null && email.length > 0 && !isEmailValid(email);
        },
        phone: function(phone) {
            localStorage.setItem("phone", phone);
            this.isPhoneValidationFailed = phone != null && phone.length > 0 && !isPhoneValid(phone);
        },
        */
        isAgreeToTermsChecked(isAgreeToTermsChecked) {
            localStorage.setItem('isAgreeToTermsChecked', isAgreeToTermsChecked === true || isAgreeToTermsChecked === 'true' ? 'true' : 'false');
            if (isAgreeToTermsChecked) {
                this.checkedAgreeToTermsDate = new Date().toISOString();
            }
        },
        checkedAgreeToTermsDate(checkedAgreeToTermsDate) {
            localStorage.setItem('checkedAgreeToTermsDate', checkedAgreeToTermsDate);
        },
        switchMoreOptions(val) {
            console.log('switchMoreOptions %s', val);
            if (val) {
                this.showMoreOptions();
            } else {
                this.hideMoreOptions();
            }
        },
        selectedPanelIndex(val) {
            console.log('selectedPanelIndex %s', val);
            if (val === 0) {
                // QR code panel is being shown, make sure we're displaying the QR code SVG now that the container is visible
                // this.$nextTick(() => this.onSvgReady(cacheSvgElement)); // should work according to documentation, but the div is still not found; but the setTimeout function works:
                setTimeout(() => this.onSvgReady(cacheSvgElement), 1);
            }
        },
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        csrfGuardToken() {
            return this.session.csrfGuardToken;
        },
        pendingAction() {
            return this.session.pendingAction;
        },
        users() {
            return this.session.rememberMeList || [];
        },

        isDisplayQRCode() {
            return this.isQrCodeReady; // the platform info now controls the available panels && ['unknown', 'windows', 'mac'].includes(this.platform);
        },
        /*
        isDisplayLinkAndroid() {
            return this.isLinkQueryReady && ['all', 'android'].includes(this.platform);
        },
        */

        // isLoginFormComplete: function() {
        //     return this.username != null && this.username.length > 0;
        // }

        isNotificationEnabled() {
            if (this.mode === 'qr' || this.mode === 'start') {
                // skip the push notifications and only show the QR code
                // example how to get here from another page: this.$router.push({ path: '/login', query: { mode: 'qr' } });
                return false;
            }
            return this.users.length > 0;
        },
    },

    methods: {
        showAppLinks() {
            this.platform = 'all';
        },
        showQRCode() {
            this.platform = 'unknown';
        },
        showNotification() {
            this.isLoginWithQrCode = false;
            this.isLoginWithPushNotification = true;
            this.resendPushNotification();
        },
        /*
        openAndroidLink() {
            console.log('openAndroidLink %s', this.linkQueryAndroid);
            window.open(this.linkQueryAndroid, '_blank');
        },
        openIOSLink() {
            console.log('openIOSLink %s', this.linkQueryIOS);
            window.open(this.linkQueryIOS, '_blank');
        },
        */
        showMoreOptions() {
            if (this.platform === 'android' || this.platform === 'ios') {
                // show a QR code to allow someone to login with another mobile device so the LoginShield app doesn't have to be on the same device
                this.isMobilePanel = true;
                this.isLoginWithQrCode = true;
                this.selectedPanelIndex = 0; // open the QR code panel as default
                // show android and ios panels
                this.isAndroidPanel = true;
                this.isIosPanel = true;
                this.isOnlyPanel = false;
            } else {
                this.isMobilePanel = true;
                this.isOnlyPanel = true;
            }
        },
        hideMoreOptions() {
            this.setActivePanel();
        },
        switchUser() {
            this.isLoginReady = false;
        },
        // called from document ready:
        async startLoginActivity() {
            console.log('startLoginActivity: platform: %s', this.platform);
            console.log('startLoginActivity: users.length: %s', this.users.length);
            this.isLoginWithPushNotification = false;
            this.isLoginWithQrCode = false;
            this.loginPushNotificationError = false;
            this.loginQrCodeError = false;
            this.isLoginTimeout = false;
            this.isLoginError = false;
            this.isLoginCancel = false;
            this.isLoginSafetyNotice = false;
            this.isLoginActivity = true;
            this.isLoginReady = false;

            if (this.interactionId === null && this.from) {
                // create a new interaction to return the user to previous page after login,
                // because login may be completed on a different device, we don't assume that
                // we're staying on this page, so we need to store that location for later
                try {
                    const result = await this.$store.dispatch('createInteraction', { type: 'view', state: { path: this.from } });
                    if (result.id) {
                        this.interactionId = result.id;
                    }
                } catch (err) {
                    console.error('startLoginActivity: failed to store return view', err);
                }
            }

            if (this.isNotificationEnabled) {
                console.log('startLoginActivity: unlock enabled');
                this.isLoginWithPushNotification = true;
                if (['windows', 'mac', 'linux', 'unknown'].includes(this.platform)) {
                    if (this.users.length === 1) {
                        console.log('startLoginActivity: calling sendPushNotification for workstation');
                        this.sendPushNotification(this.users[0].id);
                    }
                } else {
                    console.log('startLoginActivity: calling startQRCodeLogin for mobile device');
                    this.startQRCodeLogin();
                }
            } else {
                console.log('startLoginActivity: calling startQRCodeLogin');
                // immediately transition to qr code
                this.startQRCodeLogin();
            }
        },
        cancelLoginActivity() {
            gw.cancel();
            this.isLoginActivity = false;
            if (this.from) {
                this.$router.push(this.from);
            } else {
                this.$router.push('/');
            }
        },
        async startQRCodeLogin() {
            console.log('startQRCodeLogin');
            this.isLoginReady = true;
            this.isLoginWithPushNotification = false;
            this.isQrCodeReady = false;
            this.isLinkQueryReady = false;
            this.isLoadingQrCode = true;
            this.isLoginWithQrCode = true;
            this.loginQrCodeError = false;
            this.$store.commit('loading', { generateQrCode: true });
            try {
                // NOTE: interactionId may be null
                const response = await gw.loginWithNewSession({ interactionId: this.interactionId });
                if (response.interactionId) {
                    this.interactionId = response.interactionId;
                }
            } catch (err) {
                console.log('startQRCodeLogin: failed to start new session with qr code: %o', err);
                this.loginQrCodeError = true;
            }
            this.$store.commit('loading', { generateQrCode: false });
            this.isLoadingQrCode = false;
            console.log('startQRCodeLogin: login process started');
        },
        async sendPushNotification(userId) {
            if (userId) {
                this.pushNotificationUserId = userId;
            }
            this.isLoginReady = true;
            this.isLoginTimeout = false;
            this.isLoginWithQrCode = false;
            this.isPushNotificationSent = false;
            this.isLoginWithPushNotification = true;

            console.log(`sendPushNotification userId: ${userId}`);
            this.$store.commit('loading', { sendPushNotification: true });
            let result;
            try {
                // NOTE: interactionId may be null
                result = await gw.loginWithPushNotification({ userId, interactionId: this.interactionId });
                if (result.interactionId) {
                    this.interactionId = result.interactionId;
                }
            } catch (err) {
                console.log('sendPushNotification: error response: %o', err);
                result = null;
            }
            this.$store.commit('loading', { sendPushNotification: false });
            if (!result || result.fault || !result.isNotificationSent) {
                this.loginPushNotificationError = true;
                this.startQRCodeLogin();
            } else {
                this.isPushNotificationSent = true;
            }
        },
        async resendPushNotification() {
            this.sendPushNotification(this.pushNotificationUserId);
        },
        async removeLockedUserById(userId) {
            const result = await this.$store.dispatch('removeLockedUser', userId);
            if (result) {
                this.snackbarLockedUserRemoved = true;
            } else {
                this.snackbarLockedUserRemovedError = true;
            }
        },
        async onLoginResult({
            status, error, forward, route,
        } = {}) {
            switch (status) {
            case 'cancel':
                this.onLoginCancel();
                break;
            case 'safety-notice':
                this.onLoginSafetyNotice({ route });
                break;
            case 'error':
                this.onLoginError({ error });
                break;
            case 'login':
                this.onLoginDone({ forward });
                break;
            case 'timeout':
                this.onLoginTimeout();
                break;
            default:
                console.error(`Login.vue: onLoginResult: unknown status ${status}`);
            }
        },
        async onLoginDone({ forward } = {}) {
            if (forward) {
                // NOTE: this function will only be called when the authenticator trusts this
                // gateway and returns the session transfer token via the MX channel
                console.log('onLoginDone; forward: %s', forward);
                this.isLoadingUserInfo = true;
                // this.isQrCodeReady = false;
                // this.isLinkQueryReady = false;
                // this.isLoginWithPushNotification = false;

                // forward url looks like https://tigercomet/login-redirect?loginshield=https://tigercomet/service/interaction/resume?token=...
                const url = new URL(forward);
                // if the host is the same as current website, fetch the embedded URL to continue
                if (url.origin === window.location.origin) {
                    const query = new URLSearchParams(url.search);
                    const resumeInteractionURL = query.get('loginshield');
                    if (resumeInteractionURL) {
                        // state.session.csrfGuardToken
                        const headers = {};
                        if (this.csrfGuardToken) {
                            headers['CSRF-Guard'] = this.csrfGuardToken;
                        }
                        // access the forward URL; this should associate our cookie with the logged in user
                        try {
                            const forwardResponse = await ajax.get(resumeInteractionURL, {
                                headers: {
                                    ...headers,
                                    Accept: 'application/json',
                                },
                                timeout: 30 * 1000, /* 30 seconds */
                            });
                            if (forwardResponse.status === 200) {
                                console.log('login successful %o', forwardResponse.data);
                                await this.$store.dispatch('init', { force: true });
                                // this.isLoadingUserInfo = false;
                                if (forwardResponse.data.interactionId) {
                                    this.$router.push({ path: '/interaction', query: { i: forwardResponse.data.interactionId } });
                                } else if (this.interactionId) {
                                    this.$router.push({ path: '/interaction', query: { i: this.interactionId } });
                                } else if (this.from) {
                                    this.$router.push(this.from); // only applies when onLoginDone is called as a shortcut (e.g. user is already authenticated) and startLoginActivity was never actually called; if startLoginActivity was called, then it already created a 'view' interaction with the "from" parameter, and we'll go to /interaction (above) to redirect back to that location
                                } else {
                                    this.$router.push('/dashboard'); // assume this.isAuthenticated === true
                                }
                                return;
                            }

                            console.error('login forward url failed with status %s', forwardResponse.status);
                        } catch (error) {
                            console.error('Unexpected response from server: %o', error);
                        }
                    }
                }
                // otherwise, if the host is to another website just redirect
                window.location = forward;
            } else {
                console.log('onLogin called without forward url, redirecting to dashboard');
                this.$router.push('/dashboard');
            }
        },
        onLoginTimeout() {
            console.error('onLoginTimeout');
            this.isLoginWithPushNotification = false;
            this.isLoginWithQrCode = false;
            this.isQrCodeReady = false;
            this.isLinkQueryReady = false;
            this.isLoginTimeout = true;
            this.isLoadingUserInfo = false;
        },
        onLoginError(args) {
            console.error('onLoginError; args: %o', args);
            this.isLoginWithPushNotification = false;
            this.isLoginWithQrCode = false;
            this.isQrCodeReady = false;
            this.isLinkQueryReady = false;
            this.isLoginError = true;
            this.isLoadingUserInfo = false;
        },
        onLoginCancel() {
            console.error('onLoginCancel');
            this.isLoginWithPushNotification = false;
            this.isLoginWithQrCode = false;
            this.isQrCodeReady = false;
            this.isLinkQueryReady = false;
            this.isLoginCancel = true;
            this.isLoadingUserInfo = false;
        },
        onLoginSafetyNotice({ route }) {
            console.error(`onLoginSafetyNotice route ${route}`);
            if (route === 'email') {
                this.isLoginSafetyNotice = true;
                this.isLoginWithPushNotification = false;
                this.isLoginWithQrCode = false;
                this.isQrCodeReady = false;
                this.isLinkQueryReady = false;
                this.isLoadingUserInfo = false;
            } else {
                this.onLoginCancel();
            }
        },
        onLoginConnect({ svgElement, query }) {
            if (svgElement) {
                this.onSvgReady(svgElement);
            }
            if (query) {
                this.onLinkQueryReady(query);
            }
        },
        onSvgReady(svgElement) {
            console.log('svgElement: %o', svgElement);
            this.isLoadingQrCode = false;
            this.isQrCodeReady = true;
            // stash the svgElement so if the container is available later, this method
            // can be called again with the stashed svgElement
            cacheSvgElement = svgElement;
            // remove all content of the container and then add the qr code
            const container = document.getElementById('svgQrCodeContainer');
            if (container) {
                console.log('svgElement: container found');
                while (container.hasChildNodes()) {
                    container.removeChild(container.childNodes[0]);
                }
                container.appendChild(svgElement);
            } else {
                console.log('svgElement: container not found');
            }
        },
        onLinkQueryReady(querystring) {
            console.log('onLinkQueryReady: %s', querystring);
            const androidLink = `${window.location.origin}/login/android/#${querystring}`; // TODO: get android link domain from context api, use window.location.origin only as default
            this.linkQueryAndroid = androidLink;
            const iosLink = `https://loginshield.app/login/ios/#${querystring}`; // TODO: get ios link domain from context api, use window.location.origin only as default
            this.linkQueryIOS = iosLink;
            this.isLinkQueryReady = true;
        },
        async init() {
            console.log('login.vue: init, isReady: %o', this.isReady);
            this.isLoginInitError = false;
            const headers = {};
            console.log('login.vue: init: csrfGuardToken: %o', this.csrfGuardToken);
            if (this.csrfGuardToken) {
                headers['CSRF-Guard'] = this.csrfGuardToken;
            }
            gw = new BrowserGateway({
                pushNotificationURL: '/service/login/push',
                loginChallengeURL: '/service/login/challenge',
                mxShareURL: '/mx/share',
                onConnect: this.onLoginConnect.bind(this),
                onResult: this.onLoginResult.bind(this),
                /*
                onLogin: this.onLoginDone.bind(this),
                onTimeout: this.onLoginTimeout.bind(this),
                onError: this.onLoginError.bind(this),
                onCancel: this.onLoginCancel.bind(this),
                onSvgReady: this.onSvgReady.bind(this),
                onLinkQueryReady: this.onLinkQueryReady.bind(this),
                */
                loginUserTimeoutMillis: 360 * 1000, // 360 seconds; if null there won't be an application timeout and we'll keep checking until the channel expires ;  TODO:  when server changes login challenge to show "not after", then we use THAT date instead of our own configuration
                headers,
            });

            // if user is already authenticated AND there is no interaction id, we skip login and forward user to next step
            // NOTE: an interaction might require a second authentication from a user who is already authenticated
            console.log(`login.vue: init, isAuthenticated: ${this.isAuthenticated} interactionId: ${this.interactionId}`);
            if (this.isAuthenticated && !this.interactionId) {
                this.onLoginDone();
            } else {
                // either user is non-authenticated, or the user is already authenticated BUT there's
                // an interaction id so we need to do a login for that interaction
                this.$store.commit('loading', { gatewayInit: true });
                try {
                    await gw.init();
                    console.log('login.vue: gw init done, pendingAction: %s', this.pendingAction);
                    this.startLoginActivity();
                } catch (err) {
                    console.log('login.vue: init: error initializing gateway: %o', err);
                    this.isLoginInitError = true;
                }
                this.$store.commit('loading', { gatewayInit: false });
            }
        },
        setActivePanel() {
            this.isMobilePanel = false;
            this.isAndroidPanel = false;
            this.isIosPanel = false;
            this.isOnlyPanel = false;
            if (this.platform === 'windows' || this.platform === 'mac' || this.platform === 'linux') {
                this.isMobilePanel = true;
                this.selectedPanelIndex = 0;
                this.isOnlyPanel = true;
            } else if (this.platform === 'android') {
                this.isAndroidPanel = true;
                this.selectedPanelIndex = 1;
                this.isOnlyPanel = true;
            } else if (this.platform === 'ios') {
                this.isIosPanel = true;
                this.selectedPanelIndex = 2;
                this.isOnlyPanel = true;
            } else {
                this.showMoreOptions();
            }
        },
    },

    created() {
        // detect user's platform to show the most relevant action first,
        // while allowing the user to select any of the available actions
        this.platform = getNavigatorPlatform();
        console.log('created: this.platform: %s', this.platform);
        this.setActivePanel();
    },

    mounted() {
        console.log('Login.vue: mounted with isReady: %o and isAuthenticated: %o', this.isReady, this.isAuthenticated);

        const QUERY = this.$route.query;
        if (QUERY.forward) {
            console.error('deprecated parameter: forward; use localStorage instead');
        }
        if (QUERY.from && QUERY.from.startsWith('/') && !QUERY.i) {
            console.error('login.vue: storing return location: %s', QUERY.from);
            this.from = QUERY.from;
        }
        if (QUERY.i) {
            console.log('login.vue: query contains interaction id: %s', QUERY.i);
            this.interactionId = QUERY.i;
        }
        if (QUERY.mode) {
            console.log('login.vue: query contains mode: %s', QUERY.mode);
            this.mode = QUERY.mode;
        }

        if (this.isReady) {
            this.init();
        }
    },

    beforeDestroy() {
        console.log('Login.vue: beforeDestroy');
        gw.cancel();
    },
};
</script>
